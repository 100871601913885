// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Bottom_wrapper__zSw-Y {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  padding-bottom: 46px;\n}\n\n@media (max-width: 767px) {\n  .Bottom_wrapper__zSw-Y {\n    bottom: 46px;\n    padding-bottom: 0;\n  }\n}\n\n.Bottom_row__ws1q- {\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n}\n", "",{"version":3,"sources":["webpack://./src/sections/Main/components/Bottom/Bottom.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE;IACE,YAAY;IACZ,iBAAiB;EACnB;AACF;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;AACvB","sourcesContent":[".wrapper {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  padding-bottom: 46px;\n}\n\n@media (max-width: 767px) {\n  .wrapper {\n    bottom: 46px;\n    padding-bottom: 0;\n  }\n}\n\n.row {\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Bottom_wrapper__zSw-Y",
	"row": "Bottom_row__ws1q-"
};
export default ___CSS_LOADER_EXPORT___;
