// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Team_wrapper__-YiNq {\n    /* padding-bottom: 210px; */\n}\n\n.Team_inner__yydPs {\n    /* padding-top: 128px; */\n    padding-top: 80px;\n    padding-bottom: 60px;\n}\n\n@media (max-width: 1600px) {\n    .Team_inner__yydPs {\n        max-width: 1300px;\n        margin: 0 auto;\n    }\n}\n\n@media (max-width: 1300px) {\n    .Team_inner__yydPs {\n        max-width: 950px;\n        margin: 0 auto;\n        /* padding-top: 110px; */\n        padding-top: 50px;\n        padding-bottom: 50px;\n    }\n}\n\n@media (max-width: 850px) {\n    .Team_inner__yydPs {\n        padding-top: 50px;\n        padding-bottom: 40px;\n    }\n}\n\n@media (max-width: 480px) {\n    .Team_inner__yydPs {\n        padding-top: 30px;\n        padding-bottom: 20px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/sections/Team/Team.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,wBAAwB;IACxB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI;QACI,iBAAiB;QACjB,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,cAAc;QACd,wBAAwB;QACxB,iBAAiB;QACjB,oBAAoB;IACxB;AACJ;;AAEA;IACI;QACI,iBAAiB;QACjB,oBAAoB;IACxB;AACJ;;AAEA;IACI;QACI,iBAAiB;QACjB,oBAAoB;IACxB;AACJ","sourcesContent":[".wrapper {\n    /* padding-bottom: 210px; */\n}\n\n.inner {\n    /* padding-top: 128px; */\n    padding-top: 80px;\n    padding-bottom: 60px;\n}\n\n@media (max-width: 1600px) {\n    .inner {\n        max-width: 1300px;\n        margin: 0 auto;\n    }\n}\n\n@media (max-width: 1300px) {\n    .inner {\n        max-width: 950px;\n        margin: 0 auto;\n        /* padding-top: 110px; */\n        padding-top: 50px;\n        padding-bottom: 50px;\n    }\n}\n\n@media (max-width: 850px) {\n    .inner {\n        padding-top: 50px;\n        padding-bottom: 40px;\n    }\n}\n\n@media (max-width: 480px) {\n    .inner {\n        padding-top: 30px;\n        padding-bottom: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Team_wrapper__-YiNq",
	"inner": "Team_inner__yydPs"
};
export default ___CSS_LOADER_EXPORT___;
