// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Title_title__y3F5k {\n    margin-bottom: 90px;\n\n    font-family: 'Inter';\n    font-size: 72px;\n    font-weight: 700;\n    line-height: 80%;\n\n    /* letter-spacing: 0.1em; */\n\n    text-align: center;\n    text-transform: uppercase;\n\n    color: #3A6EE8;\n}\n\n@media (max-width: 1300px) {\n    .Title_title__y3F5k {\n        margin-bottom: 70px;\n        font-size: 56px;\n    }\n}\n\n@media (max-width: 850px) {\n    .Title_title__y3F5k {\n        margin-bottom: 65px;\n        font-size: 36px;\n    }\n}\n\n@media (max-width: 480px) {\n    .Title_title__y3F5k {\n        margin-bottom: 35px;\n    }\n  }", "",{"version":3,"sources":["webpack://./src/sections/Team/components/Title/Title.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;;IAEnB,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;;IAEhB,2BAA2B;;IAE3B,kBAAkB;IAClB,yBAAyB;;IAEzB,cAAc;AAClB;;AAEA;IACI;QACI,mBAAmB;QACnB,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,mBAAmB;QACnB,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,mBAAmB;IACvB;EACF","sourcesContent":[".title {\n    margin-bottom: 90px;\n\n    font-family: 'Inter';\n    font-size: 72px;\n    font-weight: 700;\n    line-height: 80%;\n\n    /* letter-spacing: 0.1em; */\n\n    text-align: center;\n    text-transform: uppercase;\n\n    color: #3A6EE8;\n}\n\n@media (max-width: 1300px) {\n    .title {\n        margin-bottom: 70px;\n        font-size: 56px;\n    }\n}\n\n@media (max-width: 850px) {\n    .title {\n        margin-bottom: 65px;\n        font-size: 36px;\n    }\n}\n\n@media (max-width: 480px) {\n    .title {\n        margin-bottom: 35px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "Title_title__y3F5k"
};
export default ___CSS_LOADER_EXPORT___;
